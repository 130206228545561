import React from 'react';
import {Col, Row} from "react-bootstrap";
import Thumb from "components/about-us/thumbnail/AboutThumbOne";
import thumb from "assets/img/about/2.jpg"
import TypedText from "components/typed-text";
import PopupVideo from "components/popup-video";

const ServiceSix = () => {
    return (
        <div className="brook-service-area plr--100 plr_lg--30 plr_md--50 plr_sm--30 mt--80">
            <Row className="row--0 bg_color--14 align-items-center video-with-thumb">
                <Col lg={6}>
                    <Thumb
                        thumb={thumb}
                        className="service-thumb authentic-service"
                    />
                </Col>

                <Col lg={6} className="ptb-md--80 ptb-sm--80">
                    <div className="service-content authentic-service plr--170 plr_lg--70 plr_md--30 plr_sm--30">
                        <h6 className="heading heading-h6 theme-color letter-spacing-3 theme-color">BEST SERVICES</h6>
                        <div className="bk-separator--30"/>
                        <h1 className="heading heading-h1 heading-font line-height-1-5 cd-headline clip is-full-width move-up wow">
                        Elevate style, Transform closet. MENA REGION
                        <br/> Where Trends 
                            <span className="cd-words-wrapper theme-color">  <TypedText
                                    content={['Ignite Innovation.', 'Illuminate Creativity.', 'Unleash Imagination!.']}
                                    typeSpeed={50}
                                    backSpeed={50}
                                    loop={true}
                                />
                            </span>
                        </h1>
                    </div>
                </Col>

                {/* <PopupVideo
                    isThumb={false}
                    iconStyleClass={'third-icon'}
             
                    gradient={false}
                    className={'position--center'}
                >
                    <video autoPlay muted controls="{false}" width="100%" height="auto">
      <source src="https://res.cloudinary.com/dzwyhgoer/video/upload/v1701933786/hashgate/ourwork/media%20production/event%20coverage/4_ydgnfq_lj1zr9.mp4" type="video/mp4" />

    </video>
                    </PopupVideo> */}
            </Row>
        </div>
    );
};

export default ServiceSix;
