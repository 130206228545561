import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkOne = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--0 ptb-md--0 ptb-sm--00 bg_color--1 ${className}`} style={{padding:"0 20"}} >
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 theme-color">ACTIVITIES</h5>
                            <div className="bk-separator--30"/>
                          
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col xl={12} lg={12} md={12} >
                        <div className="what-do">
                        
                            <p >We at White Pearl Fashion operate brands from around the world, originating from the USA, Europe, Asia. Our work spansacross the UAE and GCC countries such as Oman, Saudi Arabia, Bahrain, Kuwait and Qatar.Our ventures include leading names in Fashion & Footwear such as:•</p>
                        </div>

                    </Col>
                    <ul style={{listStyle:"none",padding:"0 0 25px"}}>
                        <li>
                         + Li-Ning - (Sports Apparel, Accessories and Footwear)
                        </li>
                        <li>+ Peak - (Sports Apparel, Accessories and Footwear)</li>
                        <li>+ Bossini - (Fashion Apparel, Accessories and Footwear)</li>
                        <li>+ Ccilu - From Japan (Eco-friendly Comfort Shoes and Slippers)</li>
                        <li>+ Argeville Fragrances</li>
                    </ul>

                </Row>
              
                
             
            </Container>
        </div>
    );
};

export default WorkOne;
