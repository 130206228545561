import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkOne = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 theme-color">WHO WE ARE?</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h3" style={{padding:" 0 0 18px 0 "}}>DUBAI'S STYLE HUB, 60+ STORES, ICONIC BRANDS, QUALITY LIVING.</h3>
                      
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col xl={12} lg={12} md={12}>
                        <div className="what-do">
                            <ListItem
                             style={{fontSize:"25px important"}}
                                marker={null}
                             
                                content={"White Pearl Fashion LLC, a Dubai-based style hub, defines elegance and trendsetting with over 60 stores featuring iconic global brands. Founded by leaders from Brooks Middle East, we bring a legacy of excellence, integrity, and a commitment to delivering quality living through the latest in fashion and lifestyle."}
                            />
                        </div>
                    </Col>

                </Row>
              
                
                <Row className="mt--40 mt_md--20 mt_sm--5 bk-list">
                    <Col xl={4} lg={3} md={4}>
                    <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                           
                                content={"+ Global Presence, Local Essence."}
                            />
                        </div>
                    </Col>

                    <Col xl={4} lg={3} md={4}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                             
                                content={"+ Quality Living, One Pair at a Time."}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                           
                                content={"+ Vision for Tomorrow's Distributor."}
                            />
                        </div>
                    </Col>

                    {/* second row  */}


                    <Col xl={4} lg={3} md={4}>
                    <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Footsteps of Success."}
                            />
                        </div>
                    </Col>

                    <Col xl={4} lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                             
                                content={"+ Casual Sneakers Redefined."}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Values We Uphold."}
                            />
                        </div>
                    </Col>
                    
                    {/* third row   */}


                    <Col xl={4} lg={3} md={4}>
                    <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Diverse Ventures Across Borders."}
                            />
                        </div>
                    </Col>

                    <Col xl={4} lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                             
                                content={"+ Crafting Arabic Sandals Worldwide."}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Adding Quality to Life."}
                            />
                        </div>
                    </Col>


                    {/* fourth row  */}

                    <Col xl={4} lg={3} md={4}>
                    <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Leading Names in Fashion & Footwear."}
                            />
                        </div>
                    </Col>

                    <Col xl={4} lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                             
                                content={"+ Fragrance Elegance."}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4}>
                        <div className="what-do mt--10">
                            <ListItem
                                marker={null}
                           
                                content={"+ Marketing Mastery."}
                            />
                        </div>
                    </Col>
                 
                    
                </Row>
            </Container>
        </div>
    );
};

export default WorkOne;
