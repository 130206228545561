import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkOne = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--0 ptb-md--0 ptb-sm--00 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-start">
                            <h5 className="heading heading-h5 theme-color">HISTORY</h5>
                            <div className="bk-separator--30"/>
                          
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col xl={12} lg={12} md={12}>
                        <div className="what-do">
                        
                            <p >The company founders were part of Brooks Middle East and have since launched a number of world class footwear brands into the region including Skechers, Diesel footwear, Nautica, Xoxo, Pony and many more..</p>
                        </div>
                        <div className="">
                           <p>Strong annual sales, year on year growth, expansion into multiple countriescoupled with experiences and countless success stories have given thefounders a strong reputation and success establishing an organization that canwork with multiple brands which are catered to the varied and ever changing consumer trends in the region.</p>
                        </div>
                    </Col>

                </Row>
              
                
             
            </Container>
        </div>
    );
};

export default WorkOne;
