import React, { Fragment, useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const PopupVideo = ({ isThumb, thumb, watchText, iconStyleClass, videoUrl, gradient, gradientClass, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <Fragment>
      <ModalVideo
        channel=""
        videoId=""
        isOpen={isOpen}
        videoUrl={videoUrl} 
        onClose={() => setIsOpen(false)}
      />
      {isThumb ? (
        <div className={`video-with-thumb text-center ${className}`}>
          <div className="thumb">
            <img src={thumb} alt="video thumb" />
          </div>
          <div className="video-btn position--center">
            <button className="play__btn" onClick={openModal}>
              <div className={`video-icon ${iconStyleClass}`} />
            </button>
          </div>
        </div>
      ) : gradient ? (
        <div className={`video-btn-2 ${className}`}>
          <button className="play__btn" onClick={openModal}>
            <div className={`icon-gradient ${gradientClass}`}>
              <div className={`video-icon ${iconStyleClass}`} />
            </div>
            {watchText && <span className="watch-text mt--30 d-block">{watchText}</span>}
          </button>
        </div>
      ) : (
        <div className={`video-btn ${className}`}>
          <button className="play__btn" onClick={openModal}>
            <div className={`video-icon ${iconStyleClass}`} />
            {watchText && <span className="watch-text mt--30 d-block">{watchText}</span>}
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default PopupVideo;
