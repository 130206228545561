import React, { Fragment, useEffect, useState, useRef  } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import SlickSlider from "components/slick";
import ImageSlider from "components/image-slider";
import PopupVideo from "components/popup-video";
import Breadcrumb from "components/breadcrumb/BreadcrumbTwo";
import breadcrumbBg from "assets/img/bg/bg-image-39.jpg";
import portDetailsData from "data/bossini";
import videoThumb from "assets/img/portfolio/big-image/portfolio-custom-layout-05-image-01.jpg";

import Slider from "../../components/sliderbrands/bossini"



const ModernPortfolioDetails = () => {

        const videoRef = useRef(null);
      
        const handleVideoEnded = () => {
          if (videoRef.current) {
            // Reset the video to the beginning
            videoRef.current.currentTime = 0;
            // Start playing again
            videoRef.current.play();
          }
        };
    

    


    const NextArrow = ({ className, onClick }) => (
        <button className={`slick-btn ${className}`} onClick={onClick}>
            <i className="fa fa-angle-right" />
        </button>
    );

    const PrevArrow = ({ className, onClick }) => (
        <button className={`slick-btn ${className}`} onClick={onClick}>
            <i className="fa fa-angle-left" />
        </button>
    );



    return (
        <>
        <Fragment>
            <Breadcrumb  bgImg={breadcrumbBg} />

            <div className="brook-portfolio-details bg_color--1 ptb--150 ptb-md--80 ptb-sm--60">
                <Container>
                           <Row>
                        <Col lg={5}>
                            <div className="portfolio-left bk-portfolio-details">
                                <div className="portfolio-main-info">
                                    <h1 className="heading heading-h3 line-height-1-42">bossini</h1>

                                    <div className="portfolio-details-list mt--60 mt_sm--30">
                                        {portDetailsData.meta.map(item => (
                                            <div className="details-list" key={item.id}>
                                                <label>{item.type}</label>
                                                <span><a href={`${process.env.PUBLIC_URL + "/"}`}>{item.text}</a></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={7}>
                            <div className="portfolio-content mt_sm--60 mt_md--80">
                                <h6 className="heading heading-h6">Beyond Us</h6>
                                <div className="desc mt--20">
                                    <p className="bk_pra">Bossini is a Hong Kong-based fashion retailer that has gained international recognition for its trendy and affordable clothing and accessories. Founded in 1987, Bossini has expanded its presence to various countries, offering a wide range of casual wear, sportswear, and accessories for men, women, and children. With a focus on delivering contemporary and comfortable fashion, Bossini has carved a niche for itself in the fast fashion industry, catering to diverse age groups and style preferences.</p>
                                    <br/>
                                    <p className="bk_pra">Known for its commitment to quality, affordability, and versatility, Bossini has become a go-to destination for individuals seeking stylish yet budget-friendly clothing options. The brand's dedication to staying on top of the latest fashion trends while maintaining accessibility to a wide customer base has earned it a loyal following in the fashion world.</p>

                                  
                                </div>
                            </div>
                        </Col>
                    </Row>
              
                   

                    <Row className="mt--150 mt_md--120 mt_sm--100">
      <Col lg={12}>
        <video
          ref={videoRef}
          autoPlay
          muted
          controls={false}
          width="100%"
          height="auto"
          style={{ borderRadius: "25px" }}
          onEnded={handleVideoEnded}
        >
          <source
            src="https://res.cloudinary.com/dzwyhgoer/video/upload/v1704880674/wpmoda/Bossini_Seasonal_V1_2_d7cvde.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Col>
    </Row>


                    </Container>
               
                        <Row style={{padding:"50px 0"}}>
                           <Slider/>
                        </Row>
               <Container>
                    <Row className="mt--100 mt_md--80 mt_sm--60">
                        <Col lg={12}>
                            <div className="portfolio-nav-list">
                                <div className="portfolio-page prev">
                                    <div className="inner">
                                        <a href={`${process.env.PUBLIC_URL + "/shopcin"}`}>
                                            <p>Prev</p>
                                            <h3 className="heading heading-h3">Shopcin <br/>STYLE UNIFIED:MULTI-BRAND HUB</h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="portfolio-page next mt_sm--30">
                                    <div className="inner">
                                        <a href={`${process.env.PUBLIC_URL + "/Li-Ning"}`}>
                                            <p>Next</p>
                                            <h3 className="heading heading-h3">Li-Ning<br/>MAKE YOUR OWN WAY</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </Container>
            </div>
        </Fragment>
          
         
        </>
    );
};

export default ModernPortfolioDetails;
