import React, { useState } from 'react';
import Input from "components/input";
import Button from "components/button";
import {Col, Row} from "react-bootstrap";

const ContactFormTwo = () => {
    const [isHovered, setHovered] = useState(false);
    return (
        <div className="contact-form contact-form--5" style={{border:'1px solid lightgrey',margin:" 0 0 50px 0",borderRadius:"25px"}}>
            <form>
                <Row>
                    <Col xs={12}>
                        <Input
                            id={'name'}
                            name={'name'}
                            type={'text'}
                            placeholder={'Name*'}
                            required={true}
                        />
                    </Col>

                    <Col xs={12} className="mt--30">
                        <Input
                            id={'email'}
                            name={'email'}
                            type={'text'}
                            placeholder={'Email*'}
                            required={true}
                        />
                    </Col>

                    <Col xs={12} className="mt--30">
                        <Input
                            id={'phone_number'}
                            name={'phone_number'}
                            type={'text'}
                            placeholder={'Phone Number'}
                            required={false}
                        />
                    </Col>

                    <Col xs={12} className="mt--30">
                        <Input
                            id={'message'}
                            name={'message'}
                            type={'textarea'}
                            placeholder={'Your Message*'}
                            required={true}
                        />
                    </Col>
                    <Col xs={12} className="mt--30 text-center">
    <Button
        btnText={'Send Message'}
        type="button"
        btnStyle={'rounded'}
        size={'sd'}
        color={'dark'}
        className="custom-button" 
    />
</Col>

                </Row>
            </form>
        </div>
    );
};

export default ContactFormTwo;
