import React from 'react'

const map = () => {
  return (
    <>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.353158100551!2d55.33593687608193!3d25.225027477692976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d60e046ee21%3A0x8685c44a904e9040!2sWHITE%20PEARL%20FASHION%20LLC!5e0!3m2!1sen!2sae!4v1704698876479!5m2!1sen!2sae" width="100%" height="450" style={{ border: "0" }} allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
    </>
  )
}

export default map